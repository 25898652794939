<!-- Localized -->
<template>
  <div class="flex items-center">
    <div class="h-4 w-4" :style="getStyle" :class="getCustomClass"></div>
    <label class="px-2">{{ label }}</label>
  </div>
</template>

<script>
export default {
  name: "status-badge",
  props: {
    color: {
      type: String,
      default: "#000",
      required: true,
    },
    label: {
      type: String,
      default: "status name",
      required: true,
    },
    customClass: {
      type: String,
      default: "",
      required: true,
    },
  },
  computed: {
    getStyle() {
      return `background-color: ${this.color}`;
    },
    getCustomClass() {
        return this.customClass;
    }
  },
};
</script>
