var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center justify-start gap-2 pr-4 w-20"},[_c('button',{staticClass:"flex items-center justify-center w-6 h-6 rounded-full",class:{
      'bg-gray-300': _vm.isLoading || _vm.isViewMode || _vm.disabledRemovingItems,
      'bg-red-500 cursor-pointer': !_vm.isLoading && !_vm.isViewMode && !_vm.disabledRemovingItems
    },attrs:{"type":"button","disabled":_vm.isLoading || _vm.isViewMode || _vm.disabledRemovingItems},on:{"click":function($event){return _vm.$emit('onRemoveItem')}}},[_c('font-awesome-icon',{staticClass:"text-sm text-white-text",attrs:{"icon":['fa', 'minus']}})],1),_c('button',{staticClass:"flex items-center justify-center w-6 h-6 rounded-full",class:{
        'bg-gray-300': _vm.isLoading || _vm.isViewMode || _vm.disabledAddingItems || _vm.model[_vm.itemIndex + 1],
        'bg-primary cursor-pointer': !_vm.isLoading && !_vm.isViewMode && !_vm.disabledAddingItems && !_vm.model[_vm.itemIndex + 1]
      },attrs:{"type":"button","disabled":_vm.isLoading || _vm.isViewMode || _vm.disabledAddingItems || _vm.model[_vm.itemIndex + 1]},on:{"click":function($event){return _vm.$emit('onAddItem')}}},[_c('font-awesome-icon',{staticClass:"text-sm text-white-text",attrs:{"icon":['fa', 'plus']}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }