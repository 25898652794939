<!-- Localized -->
<template>
  <div
    class="grouped-list flex overflow-y-hidden h-full flex-col max-h-full gap-4"
  >
    <h2
      v-if="showTitle"
      class="grouped-list__title font-semibold text-gray-600 text-lg capitalize"
    >
      {{ title }}
    </h2>

    <div v-if="showSearch" class="grouped-list__search">
      <div class="search_container flex items-center gap-4 text-gray-500">
        <SelectAllButton :active="isAllSelected" @click="selectAll" />
        <Input
          class="flex-1"
          @input="onSearchInput"
          :placeholder="$t('components.search')"
          icon="search"
        />
      </div>
    </div>

    <div
      v-if="list.length"
      class="grouped-list__list border rounded-lg divide-y overflow-y-auto scroll-bar"
    >
      <div v-for="group in list" :key="group.id" class="flex flex-1">
        <div class="w-3/6 bg-gray-100 flex items-center px-3 py-4">
          <div class="flex gap-2 items-center">
            <input
              :disabled="disabled"
              type="checkbox"
              class="checkbox checkbox-sm checkbox-primary border-box-stroke"
              :checked="isGroupSelected(group)"
              :id="group.id"
              @change="selectGroup($event, group)"
            />
            <label :for="group.id">{{ group.name }}</label>
          </div>
        </div>
        <div class="flex-1 px-3 py-4">
          <div class="flex flex-col gap-1 h-full justify-center">
            <template v-for="item in group.items">
              <div
                class="flex gap-2 items-center justify-between"
                :key="item.id"
              >
                <div class="flex gap-2 items-center">
                  <input
                    :disabled="disabled"
                    type="checkbox"
                    class="checkbox checkbox-sm checkbox-primary border-box-stroke"
                    :checked="item.selected"
                    :id="item.id"
                    @change="selectItem($event, item)"
                  />
                  <label :for="item.id">{{ item.name }}</label>
                </div>

                <!-- TODO -->
                <!-- <div
									v-if="item.description"
									v-tippy
									content="View role Permission info"
									@click="$emit('viewPermissionsInfo', item)"
									class="text-gray-600 cursor-pointer h-6 w-6 flex items-center justify-center rounded-full text-sm hover:bg-gray-200"
								>
									<font-awesome-icon icon="info-circle" class="text-primary" />
								</div> -->
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="flex py-10 justify-center h-full border rounded">
      <Empty :showEmptyIcon="true" :text="$t('components.no_result_found')" />
    </div>
  </div>
</template>

<script>
import { SelectAll as SelectAllButton } from "@shared/components/buttons/index.js";
import Input from "@shared/input";
import Empty from "@shared/empty";

export default {
  name: "grouped-list",
  components: {
    SelectAllButton,
    Input,
    Empty,
  },
  props: {
    /**
     * @typedef {Object} GroupItem
     * @property {String} id
     * @property {String} name
     * @property {Boolean} selected
     */
    /**
     * @typedef {Object} Group
     * @property {String} id
     * @property {String} name
     * @property {GroupItem[]} items
     */
    /**
     * @type {Group[]}
     */
    list: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
    showSelectAll: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "Title",
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    isAllSelected() {
      return this.list
        .flatMap((group) => group.items)
        .every((el) => el.selected);
    },
  },
  methods: {
    selectItem(event, item) {
      this.$emit("select", {
        items: [item.id],
        value: event.target.checked,
      });
    },
    selectGroup(event, group) {
      this.$emit("select", {
        items: group.items.map((el) => el.id),
        value: event.target.checked,
      });
    },
    selectAll() {
      this.$emit("select", {
        items: this.list.flatMap((group) => group.items.map((el) => el.id)),
        value: !this.isAllSelected,
      });
    },
    onSearchInput(payload) {
      this.$emit("search", payload);
    },
    isGroupSelected(group) {
      return !!group.items.length && group.items.every((el) => el.selected);
    },
  },
};
</script>

<style lang="scss" scoped>
.formulate-input {
  margin-bottom: 0;
}
</style>
