export const recursiveSearchFilter = (
  list,
  searchString,
  config = {
    filterKey: "name",
    listKey: "items",
  },
) => {
  if (!searchString.trim()) return list;

  const { filterKey , listKey }  = config;

  function filterGroup(group) {
    const groupMatches = group[filterKey].toLowerCase().includes(searchString.toLowerCase());
    
    // if current obj matches show all it nested items:
    const filteredItems = groupMatches ? group[listKey] : (group[listKey] || []).map(item => {
      if (item[listKey]) {
        const filteredSubItems = filterGroup({ ...item, [listKey]: item[listKey] })[listKey];
        if (filteredSubItems.length > 0 || item[filterKey].toLowerCase().includes(searchString.toLowerCase())) {
          return { ...item, [listKey]: filteredSubItems };
        }
      } else if (item[filterKey].toLowerCase().includes(searchString.toLowerCase())) {
        return item;
      }
      return null;
    }).filter(Boolean);
    
    if (groupMatches || filteredItems.length > 0) {
      return { ...group, [listKey]: filteredItems };
    }
    return null;
  }
  
  return list.map(filterGroup).filter(Boolean);
};
