<!-- Localized -->
<template>
    <button
        class="flex btn btn-outline bg-red-500 text-white-text btn-circle hover:bg-red-600 border-none btn-sm"
        v-bind="$attrs"
        v-on="$listeners"
    >
        <font-awesome-icon icon="trash" />
    </button>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>

</style>