import { fillNestedConfigPayload, fillNestedConfigModel } from "./utils";

export const TRIGGER_FIELDS = Object.freeze({
  Details: "details",
  Events: "events",
  Conditions: "conditions",
  Actions: "actions",
});

export const TRIGGER_FIELDS_LIST = [TRIGGER_FIELDS.Events, TRIGGER_FIELDS.Conditions, TRIGGER_FIELDS.Actions];

export const FIELD_KEYS = Object.freeze({
  Type: "type",
  Configuration: "configuration",
  SubConfiguration: "subConfiguration",
  SubSubConfiguration: "subSubConfiguration",
});

export const PAYLOAD_MAP = new Map([
  [
    TRIGGER_FIELDS.Events, [
      FIELD_KEYS.Type,
      FIELD_KEYS.Configuration,
      FIELD_KEYS.SubConfiguration,
      ...fillNestedConfigPayload(),
    ]
  ],
  [
    TRIGGER_FIELDS.Conditions, [
      FIELD_KEYS.Type,
      FIELD_KEYS.Configuration,
      FIELD_KEYS.SubConfiguration,
      ...fillNestedConfigPayload(),
    ]
  ],
  [
    TRIGGER_FIELDS.Actions, [
      FIELD_KEYS.Type,
      FIELD_KEYS.Configuration,
      FIELD_KEYS.SubConfiguration,
      ...fillNestedConfigPayload(),
    ]
  ],
]);

export const TRIGGER_FIELDS_CONFIG_MAP = {
  [TRIGGER_FIELDS.Events]: {
    idKey: "event_id",
    configIdKey: "event_config_id",
    configValueKey: "event_config_value",
    endpoint: "event",
  },
  [TRIGGER_FIELDS.Conditions]: {
    idKey: "condition_id",
    configIdKey: "condition_config_id",
    configValueKey: "condition_config_value",
    endpoint: "condition",
  },
  [TRIGGER_FIELDS.Actions]: {
    idKey: "action_id",
    configIdKey: "action_config_id",
    configValueKey: "action_config_value",
    endpoint: "action",
  },
};

export const TRIGGER_FIELDS_ENDPOINTS = {
  [TRIGGER_FIELDS.Events]: TRIGGER_FIELDS_CONFIG_MAP[TRIGGER_FIELDS.Events].endpoint,
  [TRIGGER_FIELDS.Conditions]: TRIGGER_FIELDS_CONFIG_MAP[TRIGGER_FIELDS.Conditions].endpoint,
  [TRIGGER_FIELDS.Actions]: TRIGGER_FIELDS_CONFIG_MAP[TRIGGER_FIELDS.Actions].endpoint,
};

export const DEFAULT_CONFIG_MODEL = {
  [FIELD_KEYS.Type]: "",
  [FIELD_KEYS.Configuration]: "",
  [FIELD_KEYS.SubConfiguration]: "",
};

export const DEFAULT_SUB_SUB_CONFIG_MODEL = {
  ...DEFAULT_CONFIG_MODEL,
  ...fillNestedConfigModel(""),
};

export const DEFAULT_CONFIG_OPTIONS = {
  [FIELD_KEYS.Configuration]: [],
  [FIELD_KEYS.SubConfiguration]: [],
};

export const DEFAULT_SUB_SUB_CONFIG_OPTIONS = {
  ...DEFAULT_CONFIG_OPTIONS,
  ...fillNestedConfigModel([]),
};

export const OPERATORS_LIST = [
  { name: "All", id: "and" },
  { name: "Any", id: "or" },
];

export const MAX_ITEMS_COUNT_MAP = {
  [TRIGGER_FIELDS.Conditions]: 30,
  [TRIGGER_FIELDS.Actions]: 20,
}

export const MIN_ITEMS_COUNT = 1;

export const DETAILS_ELEMENTS_CONFIG = [{
  key: "name",
  label: "Name",
  placeholder: "Trigger Name",
  validation: "bail|required:trim|max:30,length|matches:/^[a-zA-Z0-9 ]*[a-zA-Z0-9]+[a-zA-Z0-9 ]*$/",
}, {
  key: "description",
  label: "Description",
  placeholder: "Trigger Description",
  validation: "bail|max:100,length",
}];

export const EVENTS_ELEMENTS_CONFIG = [{
  key: FIELD_KEYS.Type,
  label: "Trigger Type",
  placeholder: "Select Trigger Type",
}, {
  key: FIELD_KEYS.Configuration,
  label: "Configuration",
  placeholder: "Select Configuration",
}];

export const CONDITIONS_ELEMENTS_CONFIG = [{
  key: FIELD_KEYS.Type,
  label: "Condition Type",
  placeholder: "Select Condition Type",
}, {
  key: FIELD_KEYS.Configuration,
  label: "Configuration",
  placeholder: "Select Configuration",
}];

export const ACTIONS_ELEMENTS_CONFIG = [{
  key: FIELD_KEYS.Type,
  label: "Action Type",
  placeholder: "Select Action Type",
}, {
  key: FIELD_KEYS.Configuration,
  label: "Configuration",
  placeholder: "Select Configuration",
}];
