<!-- Localized -->
<template>
  <div class="w-full">
    <!-- <div v-if="isCaseWorkflow" class="flex justify-end p-4">
      <Button
        type="button"
        text="Add new"
        class="bg-primary"
        @click="handleAddNewTrigger"
      />
    </div> --> <!--Hidden after requirements changes in WLP5.0 -->
  
    <v-table
      id="caseStatusTable"
      :columns="columns"
      sortingEnabled
      :rows="filteredRows"
      :pagination-options="paginationOptions"
      :mode="'records'"
      :total-rows="getTotalRows"
      :filters="filterTerm"
      @handleCheckbox="setTriggerStatus"
      @column-filter="onColumnFilters($event)"
      @filters-clear="clearFilters"
      :filterOptions="filterOptions"
      @page-change="onPageChange"
    >
      <template #actions="customData">
        <div class="flex justify-around">
          <!-- <button
              class="h-6 w-4 mx-2 mt-1 disabled:opacity-50"
              @click="handleWorkflowClone(customData.customData)"
              v-tippy
              :content="'Clone'"
            >
              <font-awesome-icon
                icon="clone"
                class="text-blue-600 text-lg fa-2x"
                :class="
                  customData.customData
                    ? 'text-primary hover:text-dvbrandhoveron hover:underline cursor-pointer'
                    : 'text-gray-500'
                "
              />
            </button> -->
          <button
            class="h-8 w-4 disabled:opacity-50 mx-2"
            v-tippy
            :content="'Edit'"
            @click="editTrigger(customData.customData, 'edit')"
          >
            <font-awesome-icon
              icon="edit"
              class="text-blue-600 text-lg fa-3x"
              :class="
                customData.customData
                  ? 'text-primary hover:text-dvbrandhoveron hover:underline cursor-pointer'
                  : 'text-gray-500'
              "
            />
          </button>
          <button
            class="h-8 w-4 disabled:opacity-50 mx-2"
            v-tippy
            :content="'View'"
            @click="editTrigger(customData.customData, 'view')"
          >
            <font-awesome-icon
              icon="eye"
              class="text-blue-600 text-lg fa-3x"
              :class="
                customData.customData
                  ? 'text-primary hover:text-dvbrandhoveron hover:underline cursor-pointer'
                  : 'text-gray-500'
              "
            />
          </button>
        </div>
      </template>
    </v-table>
  </div>
</template>

<script>
import Table from "@shared/dashboard-components/table-large";
// import Button from "@shared/components/button";
import { getAllTriggers, activateTrigger, cloneTrigger } from "../service.js";
import { FormatDateNew } from "@/plugins/functions";
import { onlyUnique } from "@/plugins/utils.js";
import { uuid } from "vue-uuid";

export default {
  name: "triggerAndActions",
  components: {
    "v-table": Table,
    // Button,
  },
  data() {
    return {
      columns: [
        {
          label: "Action",
          field: "action",
          config: { filter: false, type: "actions" },
          sortable: false,
          tdClass: "w-10",
        },
        {
          label: "Active",
          field: "active",
          sortable: false,
          config: { filter: false, type: "checkbox" },
          tdClass: "truncate max-w-sm justify-center",
        },
        {
          label: "Name",
          field: "name",
          sortable: true,
          config: { filter: true, type: "select" },
          tdClass: "truncate max-w-sm",
        },
        {
          label: "Description",
          field: "description",
          sortable: true,
          config: { filter: true, type: "select" },
          tdClass: "truncate max-w-sm",
        },
        {
          label: "Created At",
          field: "created_at",
          sortable: true,
          config: { filter: true, type: "source" },
          tdClass: "truncate max-w-sm",
        },
        {
          label: "Last Updated By",
          field: "modified_by",
          sortable: true,
          //   config: { filter: false, type: "show_info" },
          tdClass: "truncate max-w-sm",
        },
        {
          label: "Last Updated At",
          field: "modified_at",
          sortable: true,
          config: { filter: true, type: "select" },
          tdClass: "truncate max-w-sm",
        },
      ],
      triggerData: [],
      rowData: [],
      paginationOptions: {
        enabled: true,
        mode: "records", // Ensure this is set to 'records'
        perPage: 10, // Set the number of records per page
        dropdownAllowAll: false,
        setCurrentPage: 1,
      },
      totalRows: 0,
      filterTerm: {},
    };
  },
  async mounted() {
    await this.setAllTriggers();
  },
  computed: {
    getWorkflowId() {
      return (
        this.$route.params?.workflowId || this.$route.params?.packagetoolId
      );
    },
    getAction() {
      return this.$route.params.action;
    },
    isCaseWorkflow() {
      return this.getType === "case";
    },
    getType() {
      return this.$route.params.type;
    },
    viewMode() {
      return this.$route.name == "check admin details"
          || this.$route.params.action == "view"
          || this.$route.name == "Package Tools"
    },
    filteredRows() {
      let filteredRows = this.rowData;
      Object.keys(this.filterTerm).forEach((key) => {
        if (this.filterTerm[key]) {
          filteredRows = filteredRows.filter((row) =>
            row[key]
              ?.toString()
              .toLowerCase()
              .includes(this.filterTerm[key]?.name?.toString().toLowerCase())
          );
        }
      });
      return filteredRows;
    },
    getTotalRows() {
      return this.filteredRows.length;
    },
    filterOptions() {
      let result = this.getFilterOptions(this.columns, this.rowData);
      return result;
    },
  },
  methods: {
    FormatDateNew,
    getFilterOptions(columns, rows) {
      if (columns && rows) {
        return columns?.reduce((result, curr) => {
          result[curr.field] = rows
            ?.filter((row) => row[curr.field])
            ?.map((row) => row[curr.field])
            ?.filter(onlyUnique)
            ?.map((el) => ({ id: uuid.v4(), name: el }));
          return result;
        }, {});
      }
    },
    async handleWorkflowClone(data) {
      await cloneTrigger(this.getType, this.getWorkflowId, data.trigger_id);
      await this.setAllTriggers();
      this.$toast.success(this.$t('components.workflow.trigger_cloned_successfully'));
    },
    async setTriggerStatus(rowData) {
      const payload = {
        workflow_id: this.getWorkflowId,
        trigger_id: rowData.trigger_id,
        active: !rowData.is_active,
      };
      await activateTrigger(this.getType, payload);
      this.$toast.success(this.$t('components.workflow.status_updated_successfully'));
    },
    editTrigger(data, action) {
      this.$router.push({
        name: "Workflow Tools",
        params: {
          trigger_action: action,
          workflowTool: "createTriggerActions",
          workflowId: this.getWorkflowId,
          toolId: data.trigger_id,
        },
      });
    },
    async setAllTriggers() {
      const viewModeColumns = ["active", "action"];

      if (this.viewMode) {
        this.columns = this.columns.filter(
          (res) => !viewModeColumns.includes(res.field)
        );
      }
      this.rowData = await getAllTriggers(this.getType, this.getWorkflowId);
      this.rowData = this.rowData.data;
      this.rowData = this.rowData?.map((item) => {
        if (item.created_at || item.modified_at) {
          item.created_at = FormatDateNew(new Date(item.created_at));
          item.modified_at = FormatDateNew(new Date(item.modified_at));
          return item;
        }
      });
      this.totalRows = this.rowData.length;
      this.rowData.forEach((res) => {
        res.triggers = res.events[res.events.length - 1]?.event_config_value;
        res.actions = res.actions[res.actions.length - 1]?.action_config_value;
      });
    },
    onColumnFilters({ columnFilters }) {
      this.filterTerm = columnFilters;
      this.paginationOptions = { ...this.paginationOptions, setCurrentPage: 1 };
    },
    clearFilters() {
      this.filterTerm = {};
      this.setAllTriggers();
    },
    onPageChange(evt) {
      this.paginationOptions.setCurrentPage = evt.currentPage;
    },
    handleAddNewTrigger() {
      this.$router.push({
        name: "Workflow Tools", 
        params: {
          actions: "edit",
          type: this.getType,
          workflowTool: "createTriggerActions",
          workflowId: this.getWorkflowId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .vgt-table-large thead th {
    background-color: var(--theme-color-primary-300);
  }

  .vgt-table-large tbody {
    border-color: var(--theme-color-primary-300);
  }
}
</style>
