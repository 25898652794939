<!-- Localized -->
<template>
  <div class="flex items-center justify-start gap-2 pr-4 w-20">
    <button
      type="button"
      :disabled="isLoading || isViewMode || disabledRemovingItems"
      class="flex items-center justify-center w-6 h-6 rounded-full"
      :class="{
        'bg-gray-300': isLoading || isViewMode || disabledRemovingItems,
        'bg-red-500 cursor-pointer': !isLoading && !isViewMode && !disabledRemovingItems
      }"
      @click="$emit('onRemoveItem')"
    >
      <font-awesome-icon class="text-sm text-white-text" :icon="['fa', 'minus']" />
    </button>

    <button
      type="button"
        :disabled="isLoading || isViewMode || disabledAddingItems || model[itemIndex + 1]"
        class="flex items-center justify-center w-6 h-6 rounded-full"
        :class="{
          'bg-gray-300': isLoading || isViewMode || disabledAddingItems || model[itemIndex + 1],
          'bg-primary cursor-pointer': !isLoading && !isViewMode && !disabledAddingItems && !model[itemIndex + 1]
        }"
        @click="$emit('onAddItem')"
      >
      <font-awesome-icon class="text-sm text-white-text" :icon="['fa', 'plus']" />
    </button>
  </div>
</template>

<script>
import {
  MAX_ITEMS_COUNT_MAP,
  MIN_ITEMS_COUNT,
  } from "../constants.js";

export default {
  name: "TriggerControls",

  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    isViewMode: {
      type: Boolean,
      required: true,
    },
    model: {
      type: Array,
      required: true,
    },
    field: {
      type: String,
      required: true,
    },
    itemIndex: {
      type: Number,
      required: true,
    },
  },

  computed: {
    disabledAddingItems() {
      return this.model.length === MAX_ITEMS_COUNT_MAP[this.field];
    },
    disabledRemovingItems() {
      return this.model.length === MIN_ITEMS_COUNT;
    },
  },
}
</script>
