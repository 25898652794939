/**
 * @param {[Object, Object]} items
 * @param {string} fieldName
 */
export const sortItemsAlphabetically = ([a, b], fieldName) => {
  if (a[fieldName].toLowerCase() < b[fieldName].toLowerCase()) return -1;
  if (a[fieldName].toLowerCase() > b[fieldName].toLowerCase()) return 1;
  return 0;
};

