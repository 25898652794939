<!-- Localized -->
<template>
    <div class="w-full">
        <div v-if="showLoader" class="flex justify-center items-center w-full h-full">
           <Loader  />
        </div>

        <template v-else>
        <UISection class="mb-4">
            <template #title>
                <span class="text-white-text">{{ $t('components.workflow.details') }}</span>
            </template>

            <div class="flex gap-4 px-4">
              <template v-for="element in detailsElements">
                <FormulateInput
                    v-model="details.model[element.key]"
                    :key="element.key"
                    :validation="element.validation"
                    error-behavior="live"
                    class="flex-1"
                    :label="element.label"
                    :placeholder="element.placeholder"
                    type="text"
                    :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                    :disabled="isLoading || isViewMode"
                    @validation="($event) => validateTriggerField($event, 'details', element.key)"
                />
              </template>
            </div>
        </UISection>

        <UISection class="mb-4">
            <template #title>
                <span class="text-white-text">{{ $t('components.workflow.trigger') }}</span>
            </template>

            <div class="rounded-xl border">
                <div class="px-4 py-2 bg-primary-100 rounded-t-xl text-sm">{{ $t('components.workflow.if_the_following_is_true') }}</div>
                <div class="flex gap-4 px-4">
                   <template v-for="element in eventsElements">
                    <FormulateInput
                        v-model="events.model[element.key]"
                        :key="`events-${element.key}`"
                        validation="required"
                        class="flex-1"
                        :label="element.label"
                        :placeholder="element.placeholder"
                        type="select"
                        :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                        :options="getMappedObjectOptions('events', element.key)"
                        :disabled="isLoading || isViewMode"
                        :config="selectConfig"
                        @input="($event) => handleObjectInput($event, 'events', element.key)"
                    />
                   </template>

                    <FormulateInput
                        v-if="events.options.subConfiguration.length > 0"
                        v-model="events.model.subConfiguration"
                        validation="required"
                        class="flex-1"
                        :label="$t('components.workflow.sub_configuration')"
                        :placeholder="$t('components.workflow.select_sub_configuration')"
                        type="select"
                        :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                        :options="getMappedObjectOptions('events', 'subConfiguration')"
                        :disabled="isLoading || isViewMode"
                        :config="selectConfig"
                        @input="($event) => handleObjectInput($event, 'events', 'subConfiguration')"
                    />

                    <div
                    v-if="events.model.subConfiguration && events.options.subSubConfiguration1.length > 0"
                    class="flex gap-4 px-4"
                  >
                    <template v-for="subSubConfigKey, sscIdx in getSubSubConfigsFromItem(events.model)">
                      <FormulateInput
                        v-if="checkShowSubConfig(getSubSubConfigCombinedValue(events.model, sscIdx), 'events', getSubSubConfigCurrentKey(sscIdx))"
                        :key="`events-${sscIdx}`"
                        v-model="events.model[subSubConfigKey]"
                        class="flex-1"
                        :label="`Sub Configuration ${sscIdx + 1}`"
                        :placeholder="`Select Sub Configuration ${sscIdx + 1}`"
                        type="select"
                        :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                        :options="getMappedObjectOptions('events', subSubConfigKey)"
                        :disabled="isLoading || isViewMode"
                        :config="selectConfig"
                        @input="($event) => handleObjectInput($event, 'events', subSubConfigKey)"
                      />
                    </template>
                  </div>
                </div>
            </div>
        </UISection>

        <UISection class="mb-4">
          <template #title>
            <span class="text-white-text">{{ $t('components.workflow.condition') }}</span>
          </template>

          <div class="rounded-xl border pb-4">
            <div class="flex items-center gap-2 px-4 py-2 bg-primary-100 rounded-t-xl text-sm">
                    <span>{{ $t('components.workflow.if') }}</span>
                    <select class="w-14 h-6 rounded-md" v-model="conditions.operator" :disabled="isLoading || isViewMode">
                        <option
                            class="p-2 rounded-md"
                            v-for="operator in conditions.operators"
                            :value="operator.id"
                            :key="operator.id"
                        >
                          {{ operator.name }}
                        </option>
                    </select>
                    <span>{{ $t('components.workflow.of_the_following_is_true') }}</span>
            </div>

              <template v-for="item, index in conditions.model" >
                <div  :key="getUUID()" class="flex items-center gap-4 w-full"><!-- eslint-disable-line -->
                <div  class="grid auto-cols-1 auto-rows-auto gap-2 flex-1">
                  <div class="flex gap-4 px-4">
                    <template v-for="element in conditionsElements">
                    <FormulateInput
                        v-model="item[element.key]"
                        :key="`conditions-${element.key}`"
                        class="flex-1"
                        :label="element.label"
                        :placeholder="element.placeholder"
                        type="select"
                        :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                        :options="getMappedArrayOptions('conditions', element.key, index)"
                        :disabled="isLoading || isViewMode"
                        :config="selectConfig"
                        @input="($event) => handleArrayInput($event, index, 'conditions', element.key)"
                    />
                   </template>

                    <FormulateInput
                        v-if="checkShowSubConfig(item.configuration, 'conditions', 'configuration', index)"
                        v-model="item.subConfiguration"
                        class="flex-1"
                        :label="$t('components.workflow.sub_configuration')"
                        :placeholder="$t('components.workflow.select_sub_configuration')"
                        type="select"
                        :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                        :options="getMappedArrayOptions('conditions', 'subConfiguration', index)"
                        :disabled="isLoading || isViewMode"
                        :config="selectConfig"
                        @input="($event) => handleArrayInput($event, index, 'conditions', 'subConfiguration')"
                    />
                  </div>

                  <div
                    v-if="item.subConfiguration && conditions.options[index].subSubConfiguration1.length > 0"
                    class="flex gap-4 px-4"
                  >
                    <template v-for="subSubConfigKey, sscIdx in getSubSubConfigsFromItem(item)">
                      <FormulateInput
                        v-if="checkShowSubConfig(getSubSubConfigCombinedValue(item, sscIdx), 'conditions', getSubSubConfigCurrentKey(sscIdx), index)"
                        :key="`${index}-${sscIdx}`"
                        v-model="item[subSubConfigKey]"
                        class="flex-1"
                        :label="`Sub Configuration ${sscIdx + 1}`"
                        :placeholder="`Select Sub Configuration ${sscIdx + 1}`"
                        type="select"
                        :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                        :options="getMappedArrayOptions('conditions', subSubConfigKey, index)"
                        :disabled="isLoading || isViewMode"
                        :config="selectConfig"
                        @input="($event) => handleArrayInput($event, index, 'conditions', subSubConfigKey)"
                      />
                    </template>
                  </div>
                </div>

                <TriggerControls
                  :model="conditions.model"
                  field="conditions"
                  :itemIndex="index"
                  :isLoading="isLoading"
                  :isViewMode="isViewMode"
                  @onRemoveItem="removeItem('conditions', index)"
                  @onAddItem="addItem('conditions')"
                />
                </div>
              </template>
          </div>
        </UISection>

        <UISection class="mb-4">
            <template #title>
                <span class="text-white-text">{{ $t('components.workflow.action') }}</span>
            </template>

            <div class="rounded-xl border">
                <div class="px-4 py-2 bg-primary-100 rounded-t-xl text-sm">{{ $t('components.workflow.then_perform_the_following') }}</div>
                <div v-for="item, index in actions.model" :key="getUUID()" class="flex gap-4"> <!-- eslint-disable-line -->
                <div class="flex gap-4 px-4 flex-1">
                  <template v-for="element in actionsElements">
                    <FormulateInput
                        v-model="item[element.key]"
                        :key="`actions-${element.key}`"
                        validation="required"
                        class="flex-1"
                        :label="element.label"
                        :placeholder="element.placeholder"
                        type="select"
                        :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                        :options="getMappedArrayOptions('actions', element.key, index)"
                        :disabled="isLoading || isViewMode"
                        :config="selectConfig"
                        @input="($event) => handleArrayInput($event, index, 'actions', element.key)"
                    />
                   </template>

                    <FormulateInput
                        v-if="checkShowSubConfig(item.configuration, 'actions', 'configuration', index)"
                        v-model="item.subConfiguration"
                        validation="required"
                        class="flex-1"
                        :label="$t('components.workflow.sub_configuration')"
                        :placeholder="$t('components.workflow.select_sub_configuration')"
                        type="select"
                        :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                        :options="getMappedArrayOptions('actions', 'subConfiguration', index)"
                        :disabled="isLoading || isViewMode"
                        :config="selectConfig"
                        @input="($event) => handleArrayInput($event, index, 'actions', 'subConfiguration')"
                    />

                  <div
                    v-if="item.subConfiguration && actions.options[index].subSubConfiguration1.length > 0"
                    class="flex gap-4 px-4"
                  >
                    <template v-for="subSubConfigKey, sscIdx in getSubSubConfigsFromItem(item)">
                      <FormulateInput
                        v-if="checkShowSubConfig(getSubSubConfigCombinedValue(item, sscIdx), 'actions', getSubSubConfigCurrentKey(sscIdx), index)"
                        :key="`${index}-${sscIdx}`"
                        v-model="item[subSubConfigKey]"
                        class="flex-1"
                        :label="`Sub Configuration ${sscIdx + 1}`"
                        :placeholder="`Select Sub Configuration ${sscIdx + 1}`"
                        type="select"
                        :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                        :options="getMappedArrayOptions('actions', subSubConfigKey, index)"
                        :disabled="isLoading || isViewMode"
                        :config="selectConfig"
                        @input="($event) => handleArrayInput($event, index, 'actions', subSubConfigKey)"
                      />
                    </template>
                  </div>
                </div>

                <TriggerControls
                    :model="actions.model"
                    field="actions"
                    :itemIndex="index"
                    :isLoading="isLoading"
                    :isViewMode="isViewMode"
                    @onRemoveItem="removeItem('actions', index)"
                    @onAddItem="addItem('actions')"
                />
                </div>
            </div>
        </UISection>

        <div class="mt-4 bg-white rounded-2xl h-20 shadow-md" v-if="!isViewMode">
            <div class="flex justify-end p-4">
                <Button
                    type="button"
                    :text="getTriggerId ? 'Update' : 'Save'"
                    :disabled="isLoading || disabledSubmit"
                    class="bg-primary"
                    @click="handleSubmit"
                />
            </div>
        </div>
        </template>
    </div>
</template>

<script>
import { clone } from "lodash";
import Button from "@shared/components/button";
import Loader from "@/components/loader";
import { TriggerControls } from "./components";
import {
  fetchTriggerDetail,
  fetchTriggerFieldOptions,
  updateTrigger,
  createTrigger,
} from "./services.js"
import {
  getPayloadForArrayOfObjects,
  getPayloadForArrayOfArrays,
  getItemPayload,
  getObjectItemFromOptions,
  getArrayItemFromOptions,
  checkShowSubConfig,
} from "./composables.js"
import {
    getConfigKeys,
    getNextKey,
    getSubSubConfigCombinedValue,
    getSubSubConfigCurrentKey,
    mapObjectOptions,
    mapArrayOptions,
    validateFieldData,
    getSubSubConfigsFromItem,
    getUUID,
} from "./utils.js";
import {
  TRIGGER_FIELDS,
  TRIGGER_FIELDS_LIST,
  FIELD_KEYS,
  PAYLOAD_MAP,
  TRIGGER_FIELDS_ENDPOINTS,
  OPERATORS_LIST,
  DEFAULT_SUB_SUB_CONFIG_MODEL,
  DEFAULT_SUB_SUB_CONFIG_OPTIONS,
  DETAILS_ELEMENTS_CONFIG,
  EVENTS_ELEMENTS_CONFIG,
  CONDITIONS_ELEMENTS_CONFIG,
  ACTIONS_ELEMENTS_CONFIG,
} from "./constants.js";

export default {
  name: "createTriggersAndActions",

  components: {
    Button,
    Loader,
    TriggerControls,
  },

    data() {
        return {
            details: {
                model: {
                    name: "",
                    description: "",
                },
                errors: {
                  name: false,
                  description: false,
                }
            },
            events: {
                model: clone(DEFAULT_SUB_SUB_CONFIG_MODEL),
                options: {
                    [FIELD_KEYS.Type]: [],
                    ...DEFAULT_SUB_SUB_CONFIG_OPTIONS,
                },
                memo: {},
            },
            conditions: {
                model: [clone(DEFAULT_SUB_SUB_CONFIG_MODEL)],
                options: [{
                    [FIELD_KEYS.Type]: [],
                    ...DEFAULT_SUB_SUB_CONFIG_OPTIONS,
                }],
                memo: {},
                operator: OPERATORS_LIST[0].id,
                operators: OPERATORS_LIST,
            },
            actions: {
                model: [clone(DEFAULT_SUB_SUB_CONFIG_MODEL)],
                options: [{
                    [FIELD_KEYS.Type]: [],
                    ...DEFAULT_SUB_SUB_CONFIG_OPTIONS,
                }],
                memo: {},
            },
            isLoading: false,
            showLoader: true,
        }
    },

    async mounted() {
        try {
            if (!this.isViewMode) {
                const { Events, Conditions, Actions } = TRIGGER_FIELDS;
                const { Type } = FIELD_KEYS;

                const [events, conditions, actions] = await Promise.allSettled([
                  this.fetchOptions({ endpoint: TRIGGER_FIELDS_ENDPOINTS[Events] }),
                  this.fetchOptions({ endpoint: TRIGGER_FIELDS_ENDPOINTS[Conditions] }),
                  this.fetchOptions({ endpoint: TRIGGER_FIELDS_ENDPOINTS[Actions] })
                ]);

                this[Events].options[Type] = events?.value ?? [];
                this[Conditions].options[0][Type] = conditions?.value ?? [];
                this[Actions].options[0][Type] = actions?.value ?? [];
            }

            if (this.getTriggerId) {
                await this.getTriggerDetail();
            }
        } catch (err) {
            console.error(err);
        } finally {
            this.showLoader = false;
        }
    },

    computed: {
        getAction() {
            return this.$route.params.trigger_action;
        },
        getType() {
            return this.$route.params.type;
        },
        getWorkflowId() {
            return this.$route.params?.workflowId;
        },
        getTriggerId() {
            return this.$route.params?.toolId;
        },
        selectConfig() {
            return { label: "name", trackBy: "id" };
        },
        isViewMode() {
            return this.$route.params.action === "view"
                || this.$route.params.trigger_action === "view"
                || this.$route.name == "check admin details"
                || this.$route.name == "Package Tools";
        },
        disabledSubmit() {
            // Handle errors for null/ungetd
            const baseValidation = !(this.details?.model?.name?.trim().length)
                || Object.values(this.details?.errors || {}).some(e => e)
                || this.isInvalidEvent
                || this.isInvalidActions;

            return this.isConditionsPartiallyFilled
              ? baseValidation || this.isInvalidConditions
              : baseValidation;
        },
        isInvalidEvent() {
          return validateFieldData(this.events);
        },
        isInvalidActions() {
          return validateFieldData(this.actions);
        },
        isInvalidConditions() {
          return validateFieldData(this.conditions);
        },
        isConditionsPartiallyFilled() {
          return this.conditions.model[0].type.length > 0;
        },
        submitMessage() {
          return this.getTriggerId ? "Trigger is successfully updated" : "New Trigger is successfully created";
        },
        detailsElements() {
          return DETAILS_ELEMENTS_CONFIG;
        },
        eventsElements() {
          return EVENTS_ELEMENTS_CONFIG;
        },
        conditionsElements() {
          return CONDITIONS_ELEMENTS_CONFIG;
        },
        actionsElements() {
          return ACTIONS_ELEMENTS_CONFIG;
        }
    },

    methods: {
      getSubSubConfigCombinedValue,
      getSubSubConfigCurrentKey,
      getSubSubConfigsFromItem,
      getUUID,

        async getTriggerDetail() {
            try {
                const data = await fetchTriggerDetail({
                  type: this.getType,
                  workflow_id: this.getWorkflowId,
                  trigger_id: this.getTriggerId
                });

                await this.initModel(data);
            } catch (err) {
                console.error(err);
            } finally {
                this.showLoader = false;
            }
        },

        async initModel(triggerDetail = {}) {
            for (const field in triggerDetail) {
                if (TRIGGER_FIELDS_LIST.includes(field)) {
                    const configModelKeys = PAYLOAD_MAP.get(field);
                    const { idKey, configIdKey, configValueKey } = getConfigKeys(field);

                    if (Array.isArray(this[field].model)) {
                        for (const [fieldsSetIndex, fieldsSetArr] of triggerDetail[field].entries()) {
                            if (fieldsSetIndex > 0) this.addItem(field);

                            for (const [idx, mk] of configModelKeys.entries()) {
                                const item = fieldsSetArr[idx];
                                if (!item) continue;

                                const combinedKey = `${item[idKey]}/${item[configIdKey]}/${item[configValueKey]}`;

                                if (this.isViewMode) {
                                  this[field].options[fieldsSetIndex][mk] = [item];
                                }

                                await this.handleArrayInput(combinedKey, fieldsSetIndex, field, mk);
                                this[field].model[fieldsSetIndex][mk] = combinedKey;
                            }
                        }
                    } else {
                        for (const [idx, mk] of configModelKeys.entries()) {
                            const item = triggerDetail[field][idx];
                            if (!item) continue;
                            const combinedKey = `${item[idKey]}/${item[configIdKey]}/${item[configValueKey]}`;

                            if (this.isViewMode) {
                              this[field].options[mk] = [item];
                            }

                            await this.handleObjectInput(combinedKey, field, mk);
                            this[field].model[mk] = combinedKey;
                        }
                    }
                }
            }

            const { name, description, conditions_operator } = triggerDetail;
            this.details.model.name = name;
            this.details.model.description = description ?? "";
            this.conditions.operator = conditions_operator ?? OPERATORS_LIST[0].id;
        },

        async fetchOptions({ endpoint }, params) {
            try {
                const data = await fetchTriggerFieldOptions({
                  type: this.getType,
                  workflow_id: this.getWorkflowId,
                  endpoint,
                  params,
                });

                return data;

            } catch (err) { 
                console.error(err);
                this.isLoading = false;
            }
        },

        addItem(field) {
            this.$set(this[field].model, this[field].model.length, clone(DEFAULT_SUB_SUB_CONFIG_MODEL));
            this.$set(this[field].options, this[field].options.length, {
                type: this[field].options[0].type,
                ...DEFAULT_SUB_SUB_CONFIG_OPTIONS,
            });
        },

        removeItem(field, index) {
            this.$delete(this[field].model, index);
            this.$delete(this[field].options, index);
        },

        checkShowSubConfig(combinedValue, field, currentKey, index) {
            const func = checkShowSubConfig.bind(this);
            return func(combinedValue, field, currentKey, index);
        },

        getObjectItemFromOptions(combinedValue, field, currentKey) {
          const func = getObjectItemFromOptions.bind(this);
          return func(combinedValue, field, currentKey);
        },

        getArrayItemFromOptions(combinedValue, field, currentKey, index) {
            const func = getArrayItemFromOptions.bind(this);
            return func(combinedValue, field, currentKey, index);
        },

        async handleSubmit() {
            const includeDescription = this.details.model.description.trim().length > 0;

            const payload = {
                workflow_id: this.getWorkflowId,
                name: this.details.model.name.trim(),
                ...(includeDescription ? { description: this.details.model.description.trim() } : {}),
                conditions_operator: this.isConditionsPartiallyFilled ? this.conditions.operator : null,
                events: this.getPayloadForArrayOfObjects(TRIGGER_FIELDS.Events),
                conditions: this.isConditionsPartiallyFilled ? this.getPayloadForArrayOfArrays(TRIGGER_FIELDS.Conditions) : [],
                actions: this.getPayloadForArrayOfArrays(TRIGGER_FIELDS.Actions),
            };

            try {
                this.isLoading = true;
                if (this.getTriggerId) {
                  await updateTrigger({ type: this.getType,  trigger_id: this.getTriggerId, payload });
                } else {
                  await createTrigger({ type: this.getType, payload });
                }

                this.$toast.success(this.submitMessage);

                return this.$router.push({
                  name: "Workflow Tools",
                  params: {
                    workflowTool: "triggerAndActions",
                    workflowId: this.getWorkflowId,
                  },
                });
            } catch (err) {
                console.error(err.response.data.status_message);
                this.$toast.error(err.response.data.status_message);
            } finally {
              this.isLoading = false;
            }
        },

        async handleObjectInput(combinedValue, field, currentKey) {
          try {
            if (!field && !currentKey) return;

            const nextKey = getNextKey(field, currentKey);
            if (!nextKey) return;
            if (this.isViewMode) return;

            const { idKey, configIdKey, endpoint } = getConfigKeys(field);
            const item = this.getObjectItemFromOptions(combinedValue, field, currentKey);

            this.isLoading = true;

            if (item?.has_child) {
                const memoOpts = this[field].memo[combinedValue];

                if (!memoOpts?.length) {
                  const result = await this.fetchOptions({ endpoint }, {
                    [idKey]: item[idKey],
                    [configIdKey]: item[configIdKey]
                  });
                  this[field].options[nextKey] = result;
                  this.$set(this[field].memo, combinedValue, result);
                } else {
                  this[field].options[nextKey] = this[field].memo[combinedValue];
                }
            }

            this.resetChildModel(field, currentKey);
          } catch (err) {
            console.log("err", err);
          } finally {
            this.isLoading = false;
          }
        },

        async handleArrayInput(combinedValue, index, field, currentKey) {
            try {
                const nextKey = getNextKey(field, currentKey);
                if (!nextKey || this.isViewMode) return;

                const { idKey, configIdKey, endpoint } = getConfigKeys(field);
                const item = this.getArrayItemFromOptions(combinedValue, field, currentKey, index);

                this.isLoading = true;

                if (item?.has_child) {
                  const memoOpts = this[field].memo[combinedValue];

                  if (!memoOpts?.length) {
                     const result = await this.fetchOptions({ endpoint }, {
                      [idKey]: item[idKey],
                      [configIdKey]: item[configIdKey]
                    });
                    this[field].options[index][nextKey] = result;
                    this.$set(this[field].memo, combinedValue, result);
                  } else {
                    this[field].options[index][nextKey] = this[field].memo[combinedValue];
                  }
                }

                this.resetChildModel(field, currentKey, index);
            } catch (err) {
                console.log("err", err);
            } finally {
            this.isLoading = false;
            }
        },

        resetChildModel(field, currentKey, index) {
          const { Type, Configuration, SubConfiguration, SubSubConfiguration } = FIELD_KEYS;
          const isIndex = !isNaN(index);
          const count = getSubSubConfigsFromItem(isIndex ? this[field].model[index] : this[field].model).length;

          switch (currentKey) {
            case Type:
              if (isIndex) {
                this[field].model[index][Configuration] = "";
                this[field].model[index][SubConfiguration] = "";
                this[field].options[index][SubConfiguration] = [];
              } else {
                this[field].model[Configuration] = "";
                this[field].model[SubConfiguration] = "";
                this[field].options[SubConfiguration] = [];
              }

              this.resetSubSubConfiguration(field, "model", index, count);
              this.resetSubSubConfiguration(field, "options", index, count);
              break;

            case Configuration:
              if (isIndex) {
                this[field].model[index][SubConfiguration] = "";
              } else {
                this[field].model[SubConfiguration] = "";
              }

              this.resetSubSubConfiguration(field, "model", index, count);
              this.resetSubSubConfiguration(field, "options", index, count);
              break;

            case SubConfiguration:
              this.resetSubSubConfiguration(field, "model", index, count);
              break;

            default:
              break;
          }

          // Reset only for the SubSubConfigs
          if (currentKey.includes(SubSubConfiguration)) {
              const start = +currentKey[currentKey.length - 1];
              this.resetSubSubConfiguration(field, "model", index, count, start);
              this.resetSubSubConfiguration(field, "options", index, count, start + 1);
          }
        },

      resetSubSubConfiguration(field, dataField, index, count, start = 0) {
        const value = dataField === "model" ? "" : [];
        for (let i = start; i < count; i++) {
          if (!isNaN(index)) {
            this[field][dataField][index][`${FIELD_KEYS.SubSubConfiguration}${i + 1}`] = value;
          } else {
            this[field][dataField][`${FIELD_KEYS.SubSubConfiguration}${i + 1}`] = value;
          }
        }
      },

        getMappedObjectOptions(field, key) {
          return mapObjectOptions(this[field].options, field, key);
        },

        getMappedArrayOptions(field, key, index) {
          return mapArrayOptions(this[field].options, field, key, index);
        },

        getPayloadForArrayOfObjects(field) {
            const func = getPayloadForArrayOfObjects.bind(this);
            return func(field);
        },

        getPayloadForArrayOfArrays(field) {
            const func = getPayloadForArrayOfArrays.bind(this);
            return func(field);
        },

        getItemPayload({ el, field, key, index }) {
            const func = getItemPayload.bind(this);
            return func({ el, field, key, index })
        },

        validateTriggerField($event, field, currentKey) {
          this[field].errors[currentKey] = $event.hasErrors;
        },
    }
}
</script>
