<!-- Localized -->
<template>
  <ModalContent
    ref="status-permissions-content"
    name="status-permissions-content"
    :title="$t('pages.workflow_engine.groups')"
    :submit-button-text="$t('pages.workflow_engine.done')"
    width="1100px"
    v-bind="$attrs"
    v-on="$listeners"
    @submit="onSubmit"
    @hidden="onContentHide"
  >
    <template #content>
      <div class="grid grid-cols-2 auto-rows-fr gap-8">
        <GroupedList
          :title="$t('pages.workflow_engine.client_groups')"
          :list="filteredClientGroups"
          show-select-all
          show-search
          class="h-full flex-1"
          @select="onGroupSelect"
          @search="handleFilterResult($event, 'client')"
        />

        <ListView
          selectKey="selected"
          valueKey="title"
          :title="$t('pages.workflow_engine.tenant_groups')"
          :listItems="filteredTenantGroups"
          show-select-all
          show-search
          class="h-full overflow-y-auto"
          @handleSelect="selectAllTenantGroups"
          @rowClick="onRowClick"
          @handleFilterResult="handleFilterResult($event, 'tenant')"
        />
      </div>
    </template>
  </ModalContent>
</template>

<script>
import ModalContent from "@shared/modal-content";
import ListView from "@shared/components/lists";
import GroupedList from "@shared/components/GroupedList";
import { sortItemsAlphabetically } from "../utils/functions.js";
import { recursiveSearchFilter } from "@shared/utils/filters.js";

export default {
  name: "GroupsModal",

  components: {
    ModalContent,
    ListView,
    GroupedList,
  },

  props: {
    /**
     * Holds all tenant and client groups in two arrays
     * @property {Object[]} tenant
     * @property {Object[]} client
     */
    groups: {
      type: Object,
      required: true,
    },
    /**
     * Holds an array of assigned group ids (tenant and client)
     * By default all groups are assigned
     */
    value: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      searchValues: {
        tenant: "",
        client: "",
      },
    };
  },

  computed: {
    filteredTenantGroups() {
      return this.tenantGroups
        .filter(el => el.title?.toLowerCase()?.includes(this.searchValues.tenant.toLowerCase()))
    },

    tenantGroups() {
      return this.groups.tenant
        ?.map(el => ({ ...el, selected: this.value.includes(el.id) }))
        ?.sort((a, b) => sortItemsAlphabetically([a, b], "title"))
        ?.sort((a, b) => b.selected - a.selected) ?? []
    },

    filteredClientGroups() {
      return recursiveSearchFilter(
        this.clientGroups || [],
        this.searchValues.client,
        {
          filterKey: "name",
          listKey: "items",
        },
      );
    },

    clientGroups() {
      return this.groups.client
        .map(client => ({
          id: client.client_id,
          name: client.client_name,
          items: client.groups.map(group => ({
            id: group.group_id,
            name: group.group_name,
            selected: this.value.includes(group.group_id),
          })),
        }))
        .sort((a, b) => sortItemsAlphabetically([a, b], "name"))
        .sort((a, b) => b.items.every(el => el.selected) - a.items.every(el => el.selected))
        .sort((a, b) => b.items.some(el => el.selected) - a.items.some(el => el.selected));
    },
  },

  methods: {
    handleFilterResult(event, type) {
      this.searchValues[type] = event;
    },
    onRowClick({ row }) {
      this.$emit("input", row.id);
    },
    onGroupSelect(payload) {
      this.$emit("input", payload);
    },
    onSubmit() {
      this.$emit("onSubmit");
      this.onContentHide();
    },
    selectAllTenantGroups(event) {
      this.$emit("input", event ? this.groups.tenant.map(el => el.id) : []);
    },
    onContentHide() {
      this.searchValues.tenant = "";
      this.searchValues.client = "";
    }
  }
}
</script>

