import { uniqWith, isEqual } from "lodash";
import axios from "../../workflow-axios";

export async function fetchTriggerDetail({ type, workflow_id, trigger_id }) {
  const { data } = await axios.get(`/workflow/${type}/triggers`, {
    params: { workflow_id, trigger_id },
  });

  return data?.data?.[0] ?? {};
}

export async function fetchTriggerFieldOptions({ type, workflow_id, endpoint, params }) {
  const { data } = await axios.get(`/workflow/${type}/${endpoint}`, {
    params: {
      workflow_id,
      ...params,
    },
  });

  return uniqWith(data?.data ?? [], isEqual);
}

export async function updateTrigger({ type, trigger_id, payload }) {
  const result = await axios.put(`/workflow/${type}/triggers`, {
    ...payload,
    trigger_id,
  });

  return result;
}

export async function createTrigger({ type, payload }) {
  const result = await axios.post(`/workflow/${type}/triggers`, payload);
  return result;
}
